import { useState } from "react"
import { Link } from 'react-router-dom';


export default function Carousal({ slides }) {
    let [current, setCurrent] = useState(0);

    return (
        <div className="overflow-hidden relative">
            <div className="flex transition ease-out duration-100" style={{ transform: `translateX(-${current * 100}%)` }}>
                {
                    slides.map((s) => {
                        return (
                            <img className="w-[100vw] h-[100vh] grayscale-50" src={s} alt={s} />
                        )
                    })
                }
            </div>
            <div className="absolute bottom-0 flex justify-center gap-0 w-full">
                <div onClick={() => {
                    setCurrent(0)
                }} className={`${current === 0 ? 'bg-[#00c98f]' : 'bg-gray-800 opacity-40'} px-5 py-5 rounded-tl-lg text-white cursor-pointer`}>
                    what we do ?
                </div>
                <div onClick={() => {
                    setCurrent(1)
                }} className={`${current === 1 ? 'bg-[#00c98f]' : 'bg-gray-800 opacity-40'} px-5 py-5 text-white cursor-pointer`}>
                    Ample Udyam
                </div>
                <div onClick={() => {
                    setCurrent(2)
                }} className={`${current === 2 ? 'bg-[#00c98f]' : 'bg-gray-800 opacity-40'} px-5 py-5 text-white cursor-pointer`}>
                    Ample Wealth
                </div>
                <div onClick={() => {
                    setCurrent(3)
                }} className={`${current === 3 ? 'bg-[#00c98f]' : 'bg-gray-800 opacity-40'} px-5 py-5 rounded-tr-lg text-white cursor-pointer`}>
                    we are hiring
                </div>
            </div>
            {
                current === 0 &&
                <div>
                    <div className='absolute flex flex-col top-[25vh]'>
                        <p className='ml-10 text-gray-400 font-POP font-medium text-xl'>Building Technologies for next livestock-revolution</p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold text-5xl'>Empowering Bharat:</p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold text-5xl'>From Roots to Prosperity</p>
                    </div>
                    <div className='absolute flex top-[50vh] flex-col cursor-pointer'>
                        <div className='flex flex-row'>
                            <Link to={'/description#Financing'} className='px-3 py-3 bg-[#00c98f] ml-10 rounded-md text-white'>Financing</Link>
                            <Link to={'/description#IFinance'} className='px-3 py-3 bg-[#00c98f] ml-5 rounded-md text-white'>Input financing</Link>
                            <Link to={'/description#Advisory'} className='px-3 py-3 bg-[#00c98f] ml-5 rounded-md text-white'>Advisory</Link>
                            <Link to={'/description#Insurance'} className='px-3 py-3 bg-[#00c98f] ml-5 rounded-md text-white'>Insurance</Link>

                        </div>
                        <div className='flex flex-row mt-5'>
                            <Link to={'/description#Market'} className='px-3 py-3 bg-[#00c98f] ml-10 rounded-md text-white'>Market Linkage</Link>
                            <Link to={'/description#Farm'} className='px-3 py-3 bg-[#00c98f] ml-5 rounded-md text-white'>Farm intelligence</Link>
                            <Link to={'/description#wealth'} className='px-3 py-3 bg-[#00c98f] ml-5 rounded-md text-white'>Wealth Creation</Link>
                        </div>
                    </div>
                </div>
            }
            {
                current === 1 &&
                <div>
                    <div className='absolute flex flex-col top-[28vh]'>
                        <p className='ml-10 text-gray-400 font-POP font-medium text-xl'>Driving the Next Revolution in Agri-Tech</p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold text-5xl'>Empowering livestock: </p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold text-5xl w-[50%]'>Farmers with financing, insurance, market access, and automation</p>
                    </div>
                    <div className='absolute flex top-[65vh] flex-col cursor-pointer'>
                        <div className='flex flex-row'>
                            <Link to="/ampleudyam" className='px-3 py-3 bg-[#00c98f] ml-10 rounded-md text-white font-POP'>Know More</Link>
                        </div>
                    </div>
                </div>
            }
            {
                current === 2 &&
                <div>
                    <div className='absolute flex flex-col top-[28vh]'>
                        <p className='ml-10 text-gray-400 font-POP font-medium text-xl'>Invest in Savings for Stability and Growths</p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold text-5xl'>Securing Bharat’s future: </p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold text-5xl w-[50%]'>Invest in savings for emergencies, education, and entrepreneurship.</p>
                    </div>
                    <div className='absolute flex top-[65vh] flex-col cursor-pointer'>
                        <div className='flex flex-row'>
                            <Link to="/amplewealth" className='px-3 py-3 bg-[#00c98f] ml-10 rounded-md text-white font-POP'>Know More</Link>
                        </div>
                    </div>
                </div>
            }
            {
                current === 3 &&
                <div>
                    <div className='absolute flex flex-col top-[25vh]'>
                        <p className='ml-10 text-gray-400 font-POP font-medium text-xl'>Unlocking Transformative Opportunities in Livestock</p>
                        <p className='ml-10 mt-5 text-white font-POP font-bold text-5xl w-[50%] leading-tight'>Phenomenal opportunities in Livestock Sector</p>
                    </div>
                    <div className='absolute flex top-[60vh] flex-col cursor-pointer'>
                        <div className='flex flex-row'>
                            <div className='px-3 py-3 bg-[#00c98f] ml-10 rounded-md text-white font-POP'>Know More</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}