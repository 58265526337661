import './App.css';
import Home from './components/Home'
import Company from './components/company'
import Description from './components/description'
import Amplewealth from './components/amplewealth'
import Ampleudyam from './components/ampleudyam'
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element"
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/company",
    element: <Company />
  },
  {
    path: "/description",
    element: <Description />
  },
  {
    path: "/amplewealth",
    element: <Amplewealth />
  },
  {
    path: "/ampleudyam",
    element: <Ampleudyam />
  },
]);

function App() {
  return (
    <div>
      <ScrollToHashElement />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;