import React from 'react'
import { Link } from 'react-router-dom';

function description() {
  return (
    <div>
      <div className='absolute flex top-5 w-full justify-between'>
        <div className='flex ml-10'>
          <Link to="/"><img alt="logo" src="/logo_c.png" /></Link>
        </div>
        <div className='flex mt-10'>
          <Link className='mr-10 text-xl font-POP font-semibold' to="/company">Company</Link>
          <Link className='mr-10 text-xl font-POP font-semibold' to="/company">Ample Wealth</Link>
          <Link className='mr-10 text-xl font-POP font-semibold' to="/company">Ample Udyam</Link>
        </div>
      </div>
      <div id="Financing" className='relative top-[20vh] flex justify-between'>
        <div className='mt-14 w-[60vw]'>
          <p className='ml-[3vw] text-4xl font-POP font-medium'>Financing</p>
          <p className='ml-[3vw] text-2xl font-POP mt-3 text-[#00c98f]'>Livestock Finance</p>
          <p className='ml-[3vw] mt-3 text-slate-500 text-xl'>Right input at right time makes a huge difference in the crop production level - but unfortunately our farmers may not always have the required funds for large purchases at the right time. Our goal is to bring micro-financing for input purchase in partnership with leading agro financing institutions of the country.</p>
        </div>
        <div className='mr-10'>
          <img className='mt-[-5vh]' src='/Poultry_finance.png' alt='finance' />
        </div>
      </div>
      <div id="IFinance" className='mt-[10vh] flex justify-between items-center'>
        <div className=''>
          <img className='' src='/Poultry_finance.png' alt='finance' />
        </div>
        <div className='mr-10 w-[60vw]'>
          <p className='ml-[3vw] text-4xl font-POP font-medium'>Input Financing</p>
          <p className='ml-[3vw] text-2xl font-POP mt-3 text-[#00c98f]'>Empowering Livestock Farmers with Quality Inputs and Technology</p>
          <p className='ml-[3vw] mt-3 text-slate-500 text-xl'>We provide livestock farmers with essential, quality inputs—including healthy newborns, premium feed, precise medication, and comprehensive insurance—ensuring timely delivery at the best prices. Our advanced supply chain solutions drive direct cost savings of up to 15% and can increase farmers' earnings by up to 4x. Through expert post-sales support, real-time market linkage, and continuous livestock health monitoring, we empower farmers to maximize productivity and income, promoting sustainable practices in livestock farming.ß</p>
        </div>
      </div>
      <div id="Advisory" className='mt-[10vh] relative flex justify-between items-center'>
        <div className='w-[60vw]'>
          <p className='ml-[3vw] text-4xl font-POP font-medium'>Advisory</p>
          <p className='ml-[3vw] text-2xl font-POP mt-3 text-[#00c98f]'>Key Practices for Optimizing Livestock Health and Profitability</p>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Health & Nutrition : Provide balanced feed and ensure regular vaccinations.</li>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>⁠Breeding & Newborn Care : Use high-quality breeds and prioritize newborn health.</li>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Housing & Environment : Maintain proper space, ventilation, and cleanliness.</li>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Medication & Preventive Care : Use approved medications, monitor health, and keep essential medicines.</li>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Market-Driven Practices : Adjust feeding and care based on market cycles to maximize profits.</li>
        </div>
        <div>
          <img className='' src='/Poultry_finance.png' alt='finance' />
        </div>
      </div>
      <div id="Insurance" className='relative top-[10vh] flex justify-between items-center'>
        <div>
          <img className='' src='/Poultry_finance.png' alt='finance' />
        </div>
        <div className='w-[60vw] mr-10'>
          <p className='ml-[3vw] text-4xl font-POP font-medium'>Insurance</p>
          <p className='ml-[3vw] text-2xl font-POP mt-3 text-[#00c98f]'>Ensuring Livestock Farmers' Security with Insurance Benefits</p>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Financial Protection : Coverage for losses due to illness, accidents, and natural disasters.</li>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Income Stability : Compensation for animal loss, ensuring stable income even during setbacks.</li>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Access to Credit : Insured livestock increases farmers' access to loans by reducing lender risk.</li>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Affordable Coverage : Subsidized premiums make insurance accessible for small-scale farmers.</li>
        </div>
      </div>
      <div id="Market" className='relative top-[20vh] flex justify-between items-center'>
        <div className='w-[60vw]'>
          <p className='ml-[3vw] text-4xl font-POP font-medium'>Direct Market Linkage</p>
          <p className='ml-[3vw] text-2xl font-POP mt-3 text-[#00c98f]'>Empowering Livestock Farmers with Fair Access</p>
          <p className='ml-[3vw] mt-3 text-slate-500 text-xl'>Direct market linkage connects livestock farmers straight to buyers, eliminating middlemen and ensuring they receive fair prices for their products. This model not only boosts farmers' income but also builds a transparent supply chain that benefits all parties involved.</p>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Higher Earnings : Direct access to markets increases profit margins by removing intermediaries.</li>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Price Transparency : Farmers get real-time market rates, ensuring fair compensation for their products.</li>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Stable Demand and Supply : Reliable market connections provide consistent demand, helping farmers plan production efficiently.</li>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Quality Control : Direct feedback from buyers allows farmers to improve quality, meeting specific market needs.</li>
          <li className='ml-[3vw] mt-3 text-slate-500 text-xl'>Timely Payments : Direct transactions ensure faster, more secure payments, improving cash flow for farmers.</li>
        </div>
        <div>
          <img className='' src='/Poultry_finance.png' alt='finance' />
        </div>
      </div>
      <div id="Farm" className='relative top-[30vh] flex justify-between items-center'>
        <div>
          <img className='' src='/Poultry_finance.png' alt='finance' />
        </div>
        <div className='w-[60vw] mr-10'>
          <p className='ml-[3vw] text-4xl font-POP font-medium'>Farm Intelligence</p>
          <p className='ml-[3vw] text-2xl font-POP mt-3 text-[#00c98f]'>Linking AI & IOT to improve market prediction and disease outbreaks.</p>
          <p className='ml-[3vw] mt-3 text-slate-500 text-xl'>Right input at right time makes a huge difference in the crop production level - but unfortunately our farmers may not always have the required funds for large purchases at the right time. Our goal is to bring micro-financing for input purchase in partnership with leading agro financing institutions of the country.</p>
        </div>
      </div>
      <div id="wealth" className='relative top-[20vh] flex justify-between items-center'>
        <div className='w-[60vw] mt-14'>
          <p className='ml-[3vw] text-4xl font-POP font-medium'>Wealth Creation</p>
          <p className='ml-[3vw] text-2xl font-POP mt-3 text-[#00c98f]'>Required</p>
          <p className='ml-[3vw] mt-3 text-slate-500 text-xl'>Right input at right time makes a huge difference in the crop production level - but unfortunately our farmers may not always have the required funds for large purchases at the right time. Our goal is to bring micro-financing for input purchase in partnership with leading agro financing institutions of the country.</p>
        </div>
        <div>
          <img className='' src='/Poultry_finance.png' alt='finance' />
        </div>
      </div>
      <div className='relative mt-[25vh]'>
        <img alt="footerimage" src="/footer.png" />
      </div>
      <div className='bg-[#496E58] flex justify-between pb-12'>
        <div className='ml-20'>
          <img className='mt-5 mb-5' alt="footer" src="/amplesavings_logo.png" />
          <img className="w-[15vw] ml-5" alt="comming-soon" src="/comingsoon.png" />
          <div>

          </div>
        </div>
        <div className='flex flex-col'>
          <p className='text-white text-2xl font-POP font-bold mb-5'>More Links</p>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Financing</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Input Financing</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Advisory</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Insurance</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Market Linkage</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Farm Intelligence</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Supply Chain Financing</Link>
        </div>
        <div className='flex flex-col'>
          <p className='text-white text-2xl font-POP font-bold mb-5'>Pages</p>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">About Us</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Ample Savings</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Ample Udyam</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Contact Us</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Careers</Link>
        </div>
        <div className='flex flex-col mr-20'>
          <p className='text-white text-2xl font-POP font-bold mb-5'>Contact with us</p>
          <div className='flex flex-row'>
            <a href='https://amplesavings.com'><img className='w-12 h-12 mr-1' alt="facebook" src="/facebook.png" /></a>
            <a href='https://amplesavings.com'><img className='w-12 h-12 mr-3' alt="instagram" src="/Instagram.png" /></a>
            <a href='https://amplesavings.com'><img className='w-10 h-10 mr-4 mt-1' alt="linkedin" src="/linkedin.png" /></a>
            <a href='https://amplesavings.com'><img className='w-10 h-10 mr-3 mt-1' alt="twitter" src="/twitter.png" /></a>
          </div>
          <div className='flex mt-5'>
            <img className='w-10 mr-2' src="/email.png" alt="mail icon" />
            <Link className='text-white text-lg font-POP mt-1 font-normal' to="#" onClick={(e) => {
              window.location.href = "careers@amplesavings.com";
              e.preventDefault();
            }}>careers@amplesavings.com</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default description