import React from 'react'
import { Link } from 'react-router-dom';


function company() {
  return (
    <div>
      <div className='absolute flex top-5 w-full justify-between'>
        <div className='flex ml-10'>
          <Link to="/"><img alt="logo" src="/logo_c.png" /></Link>
        </div>
        <div className='flex mt-10'>
          <Link className='mr-10 text-xl font-POP font-semibold' to="/company">Company</Link>
          <Link className='mr-10 text-xl font-POP font-semibold' to="/amplewealth">Ample Wealth</Link>
          <Link className='mr-10 text-xl font-POP font-semibold' to="/ampleudyam">Ample Udyam</Link>
        </div>
      </div>
      <div className='relative top-[18vh] flex flex-col'>
        <div className='mt-14 w-[97vw]'>
          <p className='ml-[3vw] text-5xl font-POP font-medium text-center mb-10'>About Us</p>
          <p className='ml-[3vw] mt-5 text-slate-500 text-xl text-center'>DeHaat™ is one of the fastest-growing start-ups in the Agri-Tech sector & one of the very few companies providing end-to-end solutions & services to the farming community in India. We are building AI-enabled technologies to revolutionize supply chain & production efficiency in the farming sector.</p>
          <p className='ml-[3vw] mt-5 text-slate-500 text-xl text-center'>Currently, we are operating in 12 Indian agrarian states with an extensive network of 11,000+ DeHaat Centers & 503 FPOs, serving 1.8 million+ farmers. We also provide AI-enabled crop advisory to farmers for 30+ crops in regional languages.</p>
          <p className='ml-[3vw] mt-5 text-slate-500 text-xl text-center'>Founded by the alumni from IIT Delhi, IIT Kharagpur, IIM Ahmedabad & other top institutes - DeHaat is a fully funded start-up with an exceptional growth rate & is certified Great Place to Work 2 years in a row, 2022-2023 & 2023-2024. The impact we have created at grass root level over the last 12 years of our operations has been phenomenal  & is recognized & felicitated by NASSCOM, Forbes, ET, Niti Aayog, Bill Gates Foundation & many more.</p>
        </div>
        <div className='flex relative mt-[10vh]'>
          <div className='w-[30vw] h-[30vh] bg-[#a6dbab] rounded-tl-[15vh] rounded-b-[2vh] rounded-tr-[2vh] ml-10 mt-16'>
            <div className='absolute'>
              <p className='mt-12 ml-12 font-POP text-2xl font-bold text-white'>Pruthiraj Rath</p>
              <a href="https://www.linkedin.com/in/pruthiraj-rath-91040a111"><img className='w-10 ml-12 mt-[10vh]' alt="Linkedin_pruthiraj" src="/linkedin.png" /></a>
            </div>
            <div>
              <img className='rounded-full border-4 border-white h-[20vh] ml-[17vw] mt-[7vh]' alt="photo_pruthiraj" src="/p_photo.jpeg" />
            </div>
          </div>
          <div className='w-[30vw] h-[30vh] bg-[#a6dbab] rounded-tl-[15vh] rounded-b-[2vh] rounded-tr-[2vh] ml-10 mt-16'>
            <div className='absolute'>
              <p className='mt-12 ml-12 font-POP text-2xl font-bold text-white'>Raj Senapati</p>
              <a href="https://www.linkedin.com/in/rajsenapati"><img className='w-10 ml-12 mt-[10vh]' alt="Linkedin_pruthiraj" src="/linkedin.png" /></a>
            </div>
            <div>
              <img className='rounded-full border-4 border-white h-[20vh] ml-[17vw] mt-[7vh]' alt="photo_pruthiraj" src="/r_photo.jpeg" />
            </div>
          </div>
          <div className='w-[30vw] h-[30vh] bg-[#a6dbab] rounded-tl-[15vh] rounded-b-[2vh] rounded-tr-[2vh] ml-10 mt-16'>
            <div className='absolute'>
              <p className='mt-12 ml-12 font-POP text-2xl font-bold text-white'>Udit Mishra</p>
              <a href="https://www.linkedin.com/in/udit-prasad-mishra-028007b9"><img className='w-10 ml-12 mt-[10vh]' alt="Linkedin_pruthiraj" src="/linkedin.png" /></a>
            </div>
            <div>
              <img className='rounded-full border-4 border-white h-[20vh] ml-[17vw] mt-[7vh]' alt="photo_pruthiraj" src="/u_photo.jpeg" />
            </div>
          </div>
        </div>
      </div>
      <div className='relative mt-[35vh] ml-[3vw] flex'>
        <div className='w-[32vw]'>
          <img className='h-[15vh] ml-[12vw] mb-5' alt="mission" src="/mission.png" />
          <p className='text-center mb-5 font-POP text-2xl font-semibold'>Mission</p>
          <p className='text-center font-POP text-xl font-extralight'>To be the preferred last mile rural commerce platform by connecting over 650K villages across India.</p>
        </div>
        <div className='w-[32vw]'>
          <img className='h-[15vh] ml-[12vw] mb-5' alt="mission" src="/sight.png" />
          <p className='text-center mb-5 font-POP text-2xl font-semibold'>Vision</p>
          <p className='text-center font-POP text-xl font-extralight'>To be the preferred last mile rural commerce platform by connecting over 650K villages across India.</p>
        </div>
        <div className='w-[32vw]'>
          <img className='h-[15vh] ml-[12vw] mb-5' alt="mission" src="/customer.png" />
          <p className='text-center mb-5 font-POP text-2xl font-semibold'>Values</p>
          <p className='text-center font-POP text-xl font-extralight'>To be the preferred last mile rural commerce platform by connecting over 650K villages across India.</p>
        </div>
      </div>
      <div className='relative mt-[25vh]'>
        <img alt="footerimage" src="/footer.png" />
      </div>
      <div className='bg-[#496E58] flex justify-between pb-12'>
        <div className='ml-20'>
          <img className='mt-5 mb-5' alt="footer" src="/amplesavings_logo.png" />
          <img className="w-[15vw] ml-5" alt="comming-soon" src="/comingsoon.png" />
          <div>

          </div>
        </div>
        <div className='flex flex-col'>
          <p className='text-white text-2xl font-POP font-bold mb-5'>More Links</p>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Financing</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Input Financing</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Advisory</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Insurance</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Market Linkage</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Farm Intelligence</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Supply Chain Financing</Link>
        </div>
        <div className='flex flex-col'>
          <p className='text-white text-2xl font-POP font-bold mb-5'>Pages</p>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">About Us</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Ample Savings</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Ample Udyam</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Contact Us</Link>
          <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Careers</Link>
        </div>
        <div className='flex flex-col mr-20'>
          <p className='text-white text-2xl font-POP font-bold mb-5'>Contact with us</p>
          <div className='flex flex-row'>
            <a href='https://amplesavings.com'><img className='w-12 h-12 mr-1' alt="facebook" src="/facebook.png" /></a>
            <a href='https://amplesavings.com'><img className='w-12 h-12 mr-3' alt="instagram" src="/Instagram.png" /></a>
            <a href='https://amplesavings.com'><img className='w-10 h-10 mr-4 mt-1' alt="linkedin" src="/linkedin.png" /></a>
            <a href='https://amplesavings.com'><img className='w-10 h-10 mr-3 mt-1' alt="twitter" src="/twitter.png" /></a>
          </div>
          <div className='flex mt-5'>
            <img className='w-10 mr-2' src="/email.png" alt="mail icon" />
            <Link className='text-white text-lg font-POP mt-1 font-normal' to="#" onClick={(e) => {
              window.location.href = "careers@amplesavings.com";
              e.preventDefault();
            }}>careers@amplesavings.com</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default company