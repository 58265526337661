import React from 'react'
import { Link } from 'react-router-dom';

function ampleudyam() {
    return (
        <div className=''>
            <div className='absolute flex top-5 w-full justify-between'>
                <div className='flex ml-10'>
                    <Link to="/"><img alt="logo" src="/logo_c.png" /></Link>
                </div>
                <div className='flex mt-10'>
                    <Link className='mr-10 text-xl font-POP font-semibold' to="/company">Company</Link>
                    <Link className='mr-10 text-xl font-POP font-semibold' to="/amplewealth">Ample Wealth</Link>
                    <Link className='mr-10 text-xl font-POP font-semibold' to="/ampleudyam">Ample Udyam</Link>
                </div>
            </div>
            <div className='mt-[25vh] mb-20'>
                <p className='font-POP text-3xl font-bold text-center mb-16'>Ample Udyam</p>
                <p className='ml-[10vw] mr-[10vw] text-center font-POP'>Hesa’s asset light model offering a unified platform for product & services facilitates financial and digital inclusion upto the last mile. Our focus on social commerce that is viable at a unit-economic level makes us the perfect rural partner for businesses at both ends of the value chain. Our hyperlocal approach helps us retain local talent and sensitizes us on all local challenges that we can address and opportunities that our partners can leverage on.</p>
            </div>
            <div className='bg-[#f6f3ed] pt-2'>
                <p className='font-POP text-3xl font-bold text-center mb-5 mt-16'>How it works ?</p>
                <div className='flex mt-20 ml-[7vw]'>
                    <div>
                        <div className='flex'>
                            <div className='rounded-full w-[15vh] h-[15vh] bg-white'>
                                <div>
                                    <img className='w-[5vw] ml-5 pt-5' alt="app_download" src="/download.png" />
                                </div>
                            </div>
                            <div>
                                <img className='w-12 mt-[4vh] ml-[4vw] mr-[4vw]' alt="arrow" src="/next.png" />
                            </div>
                        </div>
                        <div className='mt-10 mb-10'>
                            <p>Download the App</p>
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='rounded-full w-[15vh] h-[15vh] bg-white'>
                                <div>
                                    <img className='w-[5vw] ml-5 pt-5' alt="apply_loan" src="/loan.png" />
                                </div>
                            </div>
                            <div>
                                <img className='w-12 mt-[4vh] ml-[4vw] mr-[4vw]' alt="arrow" src="/next.png" />
                            </div>
                        </div>
                        <div className='mt-10 mb-10'>
                            <p>Apply for a livestock loan</p>
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='rounded-full w-[15vh] h-[15vh] bg-white'>
                                <div>
                                    <img className='w-[5vw] ml-5 pt-5' alt="ample_saathi" src="/retail-store.png" />
                                </div>
                            </div>
                            <div>
                                <img className='w-12 mt-[4vh] ml-[4vw] mr-[4vw]' alt="arrow" src="/next.png" />
                            </div>
                        </div>
                        <div className='mt-10 mb-10'>
                            <p>Find Ample Saathi nearby</p>
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='rounded-full w-[15vh] h-[15vh] bg-white'>
                                <div>
                                    <img className='w-[5vw] ml-5 pt-5' alt="agri_inputs" src="/hen.png" />
                                </div>
                            </div>
                            <div>
                                <img className='w-12 mt-[4vh] ml-[4vw] mr-[4vw]' alt="arrow" src="/next.png" />
                            </div>
                        </div>
                        <div className='mt-10 mb-10'>
                            <p>Buy Agri Inputs</p>
                        </div>
                    </div>
                    <div>
                        <div className='flex'>
                            <div className='rounded-full w-[15vh] h-[15vh] bg-white'>
                                <div>
                                    <img className='w-[5vw] ml-5 pt-5' alt="app_download" src="/repay.png" />
                                </div>
                            </div>
                        </div>
                        <div className='mt-10 mb-10'>
                            <p>Repay after harvest</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='mt-20'>
                    <p className='text-center text-gray-600 font-POP mb-5'>why Ample Udyam?</p>
                    <p className='text-center text-3xl font-semibold font-POP'>Providing the best quality and solutions to your equipment</p>
                </div>
                <div className='flex'>
                    <div className='flex flex-col w-[25vw] ml-[8vw] mt-20'>
                        <img className='w-10' alt="flexible" src="/icon-flex.jpg" />
                        <p className='mt-5 font-POP'>Flexible Financing</p>
                        <p className='mt-5'>Ample Udyam offers customizable financing to support investments in Input financing for every cycle in livestock farming.</p>
                    </div>
                    <div className='flex flex-col w-[25vw] ml-20 mt-20'>
                        <img className='w-10' alt="flexible" src="/onboarding.png" />
                        <p className='mt-5 font-POP'>Digital Onboarding</p>
                        <p className='mt-5'>Farmers will digitally onboard themselves or with the help of Ample Saathi to avail financing and guidance.</p>
                    </div>
                    <div className='flex flex-col w-[25vw] ml-20 mt-20'>
                        <img className='w-10' alt="flexible" src="/inputs.png" />
                        <p className='mt-5 font-POP'>Quality Inputs</p>
                        <p className='mt-5'>Ample udyam provides best in class inputs required for farmers to get best results like feed, medicines etc.</p>
                    </div>
                </div>
                <div className='flex'>
                    <div className='flex flex-col w-[25vw] ml-[8vw] mt-20'>
                        <img className='w-10' alt="flexible" src="/icon-train.jpg" />
                        <p className='mt-5 font-POP'>Training</p>
                        <p className='mt-5'>Ample udyam provides training related to growing and maintaining a livestock with best in class training and ample saathi to guide you every step ofthe way.</p>
                    </div>
                    <div className='flex flex-col w-[25vw] ml-20 mt-20'>
                        <img className='w-10' alt="flexible" src="/icon-tech.jpg" />
                        <p className='mt-5 font-POP'>Technical Support</p>
                        <p className='mt-5'>Farmers will receive ongoing technical support to ensure that birds remain at their optimum health and obtain bext FCR. Our guidance include using AI and IOT based data inputs to provide on-ground support.</p>
                    </div>
                    <div className='flex flex-col w-[25vw] ml-20 mt-20'>
                        <img className='w-10' alt="flexible" src="/store.png" />
                        <p className='mt-5 font-POP'>Marketing Access</p>
                        <p className='mt-5'>Ample udyam helps farmers connect to sellers digitally and broker optimum price for their produce and get payments digitally.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className='mt-[20vh]'>
                    <p className='text-center text-gray-600 font-POP mb-5'>Exploring opportunities with Ample Udyam?</p>
                    <p className='text-center text-3xl font-semibold font-POP'>Ensure you meet our expectations</p>
                </div>
                <div className='flex ml-[10vw] mr-[10vw] mt-20'>
                    <div className='w-[35vw] rounded-lg hover:border-[#f8cc48] hover:border-2 mr-[10vw]'>
                        <img className='ml-[10vw] mb-10 mt-5' alt="farm" src="/farm-own.webp" />
                        <div className='flex ml-[8vw] mb-10'>
                            <img className='w-10' alt="tick" src="/tick.png" />
                            <p className='mt-2 ml-2'>Own land and a farm.</p>
                        </div>
                    </div>
                    <div className='w-[35vw] rounded-lg hover:border-[#f8cc48] hover:border-2'>
                        <img className='ml-[10vw] mb-10 mt-5' alt="farm" src="/contract.webp" />
                        <div className='flex ml-[8vw] mb-10'>
                            <img className='w-10' alt="tick" src="/tick.png" />
                            <p className='mt-2 ml-2'>Ready to sign a contract.</p>
                        </div>
                    </div>
                </div>
                <div className='flex ml-[10vw] mr-[10vw] mt-20'>
                    <div className='w-[35vw] rounded-lg hover:border-[#f8cc48] hover:border-2 mr-[10vw]'>
                        <img className='ml-[10vw] mb-10 mt-5' alt="farm" src="/collateral.webp" />
                        <div className='flex ml-[8vw] mb-10'>
                            <img className='w-10' alt="tick" src="/tick.png" />
                            <p className='mt-2 ml-2'>Prepare collateral (securities).</p>
                        </div>
                    </div>
                    <div className='w-[35vw] rounded-lg hover:border-[#f8cc48] hover:border-2'>
                        <img className='ml-[10vw] mb-10 w-[10vw] mt-5' alt="farm" src="/service.png" />
                        <div className='flex ml-[8vw] mb-10'>
                            <img className='w-10' alt="tick" src="/tick.png" />
                            <p className='mt-2 ml-2'>Must be in Servicable Pincode</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='relative mt-[25vh]'>
                    <img alt="footerimage" src="/footer.png" />
                </div>
                <div className='bg-[#496E58] flex justify-between pb-12'>
                    <div className='ml-20'>
                        <img className='mt-5 mb-5' alt="footer" src="/amplesavings_logo.png" />
                        <img className="w-[15vw] ml-5" alt="comming-soon" src="/comingsoon.png" />
                        <div>

                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <p className='text-white text-2xl font-POP font-bold mb-5'>More Links</p>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Financing</Link>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Input Financing</Link>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Advisory</Link>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Insurance</Link>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Market Linkage</Link>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Farm Intelligence</Link>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Supply Chain Financing</Link>
                    </div>
                    <div className='flex flex-col'>
                        <p className='text-white text-2xl font-POP font-bold mb-5'>Pages</p>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">About Us</Link>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Ample Savings</Link>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Ample Udyam</Link>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Contact Us</Link>
                        <Link className='text-white text-xl font-POP mb-2 font-normal' to="/description">Careers</Link>
                    </div>
                    <div className='flex flex-col mr-20'>
                        <p className='text-white text-2xl font-POP font-bold mb-5'>Contact with us</p>
                        <div className='flex flex-row'>
                            <a href='https://amplesavings.com'><img className='w-12 h-12 mr-1' alt="facebook" src="/facebook.png" /></a>
                            <a href='https://amplesavings.com'><img className='w-12 h-12 mr-3' alt="instagram" src="/Instagram.png" /></a>
                            <a href='https://amplesavings.com'><img className='w-10 h-10 mr-4 mt-1' alt="linkedin" src="/linkedin.png" /></a>
                            <a href='https://amplesavings.com'><img className='w-10 h-10 mr-3 mt-1' alt="twitter" src="/twitter.png" /></a>
                        </div>
                        <div className='flex mt-5'>
                            <img className='w-10 mr-2' src="/email.png" alt="mail icon" />
                            <Link className='text-white text-lg font-POP mt-1 font-normal' to="#" onClick={(e) => {
                                window.location.href = "careers@amplesavings.com";
                                e.preventDefault();
                            }}>careers@amplesavings.com</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ampleudyam