import { Link } from 'react-router-dom';
import Carousal from './carousal.component';

export default function Home() {
    let slides = [
        "/Financing.png",
        "/Financing2.png",
        "/Financing3.png",
        "/hiring.png"
    ];
    return (
        < div className='w-[100%] h-[100%] relative overflow-hidden' >
            <div className='flex'>
                <Carousal slides={slides} />
            </div>
            <div className='absolute flex top-5 left-10 w-full justify-between'>
                <div className='flex'>
                    <Link to="/"><img alt="logo" src="/logo_c.png" /></Link>
                </div>
                <div className='flex items-center mr-16'>
                    <Link className='mr-10 text-white text-xl font-POP font-semibold' to="/company">Company</Link>
                    <Link className='mr-10 text-white text-xl font-POP font-semibold' to="/amplewealth">Ample Wealth</Link>
                    <Link className='mr-10 text-white text-xl font-POP font-semibold' to="/ampleudyam">Ample Udyam</Link>
                </div>
            </div>
        </div >
    )
}